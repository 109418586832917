<template>
  <div class="schedule--field">
    <div class="d-flex align-center">
      <span class="left--text">{{ index + 1 }}. </span>
      <div class="middle--col">
        <div class="d-flex align-items-start">
          <div
            class="input--field mr-4"
            :class="{ 'has-error': validation.hasError('dayOfTheWeek') }"
          >
            <multiselect
              name="dayOfTheWeek"
              id="dayOfTheWeek"
              v-model="schedule.day_of_the_week"
              :options="dayOfTheWeeks"
              @input="inputDayOfTheWeek"
              :searchable="true"
              :close-on-select="true"
              select-label=""
              selected-label=""
              deselect-label=""
              track-by="id"
              label="name"
              :placeholder="$t('listing.modal.scheduleRepost.dayOfTheWeekPlc')"
            />
            <div
              class="val-error"
              v-if="validation.hasError('dayOfTheWeek') && !checkSameDateTime()"
            >
              {{ validation.firstError('dayOfTheWeek') }}
            </div>
          </div>
          <div class="input--field" :class="{ 'has-error': validation.hasError('time') }">
            <multiselect
              name="time"
              id="time"
              v-model="schedule.time"
              :options="times"
              @input="inputTime"
              :searchable="true"
              :close-on-select="true"
              select-label=""
              selected-label=""
              deselect-label=""
              track-by="name"
              label="name"
              :placeholder="$t('listing.modal.scheduleRepost.timePlc')"
            />
            <div class="val-error" v-if="validation.hasError('time')">
              {{ validation.firstError('time') }}
            </div>
          </div>
        </div>
        <div class="d-flex align-center">
          <div class="input--field mr-4 text-center">
            {{ $t('listing.modal.scheduleRepost.expiredAt') }}:
          </div>
          <div class="input--field" :class="{ 'has-error': validation.hasError('expiredAt') }">
            <date-picker
              v-model="schedule.expired_at"
              :minDate="new Date()"
              :inline="false"
              format="dd MMMM yyyy"
              @input="inputExpired"
              :placeholder="$t('listing.modal.scheduleRepost.expiredPlc')"
            ></date-picker>
            <div class="val-error" v-if="validation.hasError('expiredAt')">
              {{ validation.firstError('expiredAt') }}
            </div>
          </div>
        </div>
      </div>
      <img
        v-show="scheduleLength > 0"
        src="@/assets/img/icons/trash.svg"
        @click="removeSchedule"
        class="trash--button"
      />
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { Validator } from 'simple-vue-validator';
import { mapState } from 'vuex';
require('vue-multiselect/dist/vue-multiselect.min.css');
const DatePicker = () => import('@/components/utils/date-picker');

export default {
  name: 'schedule-field',
  props: ['schedule', 'index', 'scheduleLength'],
  components: { Multiselect, DatePicker },
  validators: {
    dayOfTheWeek: {
      validator(value) {
        return Validator.value(value)
          .required(this.$i18n.t('errors.scheduleRepost.dayOfTheWeek.required'))
          .custom(() => {
            if (this.checkSameDateTime()) {
              return this.$i18n.t('errors.scheduleRepost.dayOfTheWeek.exist');
            }
          });
      },
    },
    time: {
      validator(value) {
        return Validator.value(value === undefined ? null : value)
          .required(this.$i18n.t('errors.scheduleRepost.time.required'))
          .custom(() => {
            if (this.checkSameDateTime()) {
              return this.$i18n.t('errors.scheduleRepost.time.exist');
            }
          });
      },
    },
    expiredAt: {
      validator(value) {
        return Validator.value(value)
          .required(this.$i18n.t('errors.scheduleRepost.expired.required'))
          .custom(() => {
            let today = new Date();
            today.setHours(0, 0, 0, 0);
            if (value && new Date(value) < today) {
              return this.$i18n.t('errors.scheduleRepost.expired.expired');
            }
          });
      },
    },
  },
  computed: {
    ...mapState({
      repostSchedules: (state) => state.listing.repostSchedules,
      dayOfTheWeeks: (state) => state.listing.dayOfTheWeeks,
      times: (state) => state.listing.times,
    }),
    dayOfTheWeek() {
      return this.schedule ? this.schedule.day_of_the_week : null;
    },
    time() {
      return this.schedule ? this.schedule.time : null;
    },
    expiredAt() {
      return this.schedule ? this.schedule.expired_at : null;
    },
  },
  methods: {
    checkSameDateTime() {
      let numberFound = 0;
      for (let schedule of this.repostSchedules) {
        if (
          (schedule.day_of_the_week ? schedule.day_of_the_week.id : null) ===
            (this.dayOfTheWeek ? this.dayOfTheWeek.id : null) &&
          (schedule.time ? schedule.time.name : null) === (this.time ? this.time.name : null)
        ) {
          numberFound++;
        }
      }
      return numberFound >= 2;
    },
    inputDayOfTheWeek(input) {
      this.$store.commit('listing/INPUT_SCHEDULE_DAY_OF_THE_WEEK', {
        day_of_the_week: input,
        index: this.index,
      });
    },
    inputTime(input) {
      this.$store.commit('listing/INPUT_SCHEDULE_TIME', {
        time: input,
        index: this.index,
      });
    },
    inputExpired(input) {
      this.$store.commit('listing/INPUT_SCHEDULE_EXPIRED', {
        expired_at: input,
        index: this.index,
      });
    },
    removeSchedule() {
      this.$store.commit('listing/REMOVE_REPOST_SCHEDULES', this.index);
    },
    async validate() {
      return this.$validate().then(
        function (success) {
          return !!success;
        }.bind(this),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/utils/variables';
.schedule--field {
  margin-bottom: 8px;
  .left--text {
    margin-right: 16px;
  }
  .middle--col {
    width: 100%;
    .input--field {
      width: 100%;
      margin-bottom: 8px;
    }
  }
  .val-error {
    margin-left: 16px;
  }
}
</style>
