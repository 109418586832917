<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    class="listing--form-modal action--modal"
    @opened="opened"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <section-loading :show="loading" />
      <button type="button" class="close" @click="close">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
      <section class="modal--container">
        <div class="subtitle--text">
          {{ $t('listing.modal.scheduleRepost.title') }}
        </div>
        <schedule-repost-field
          v-for="(schedule, index) in repostSchedules"
          :key="index"
          :index="index"
          :schedule="schedule"
          ref="scheduleField"
          :scheduleLength="repostSchedules.length"
        ></schedule-repost-field>
        <button
          @click="addSchedule"
          class="btn btn-primary--light btn-block add--button"
          type="button"
          id="scheduleBtn"
        >
          {{ $t('listing.modal.scheduleRepost.addSchedule') }}
        </button>
        <!--        <div class="action&#45;&#45;info">-->
        <!--          <div class="info&#45;&#45;title">{{ $t('listing.modal.scheduleRepost.info') }}</div>-->
        <!--          <div class="row">-->
        <!--            <div class="col-6 info&#45;&#45;coin">{{ $t('listing.modal.scheduleRepost.usedCoin') }}</div>-->
        <!--            <div class="col-6 info&#45;&#45;my-coin">-->
        <!--              <div class="coin&#45;&#45;use">-->
        <!--                {{ `1 ${$t('general.coin')}` }}-->
        <!--              </div>-->
        <!--              <div class="coin&#45;&#45;remaining">-->
        <!--                {{ `${$t('listing.modal.scheduleRepost.yourCoin')} ${totalCoins}` }}-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="bottom--part" style="margin-bottom: 12px">
          <a class="btn btn-primary" @click="repostListing">
            {{ $t('listing.modal.scheduleRepost.btn') }}
          </a>
        </div>
      </section>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
const SectionLoading = () => import('@/components/content-loading/section-loading');
import ScheduleRepostField from '@/components/coins-usage/modals/partials/schedule-repost-field';

export default {
  name: 'schedule-repost-listing-modal',
  components: {
    SectionLoading,
    ScheduleRepostField,
  },
  computed: {
    ...mapState({
      loading: (state) => state.coins.loading,
      totalCoins: (state) => state.coins.totalCoins,
      repostSchedules: (state) => state.listing.repostSchedules,
    }),
  },
  data() {
    return {
      modalName: 'schedule-repost-listing-modal',
      submitAction: null,
    };
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    async beforeOpen(event) {
      if (event.params) {
        if (event.params.submitAction !== null) {
          this.submitAction = event.params.submitAction;
        }
        if (event.params.listingUuid !== null) {
          try {
            await this.$store.dispatch('listing/getRepostSchedules', event.params.listingUuid);
          } catch (e) {
            console.log('ERROR GET REPOST SCHEDULES: ', e);
            this.close();
          }
        }
      }
    },
    async opened() {
      await this.$store.dispatch('coins/getTotalCoins');
    },
    addSchedule() {
      this.$store.commit('listing/ADD_REPOST_SCHEDULES', {
        day_of_the_week: null,
        time: null,
        expired_at: null,
      });
      // this.$nextTick(function () {
      //   this.$refs.scheduleField[this.repostSchedules.length - 1].$el
      //     .querySelector('input')
      //     .focus();
      // });
    },
    async validate() {
      let validationArray = [];
      if (this.$refs.scheduleField) {
        for (let scheduleField of this.$refs.scheduleField) {
          validationArray.push(scheduleField.validate());
        }
      }
      return Promise.all(validationArray).then(function (results) {
        return (
          results.filter(function (result) {
            return !result;
          }).length === 0
        );
      });
    },
    async repostListing() {
      let valid = await this.validate();
      if (valid) {
        this.$emit('submit');
        if (this.submitAction !== null) {
          this.submitAction();
        }
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/utils/variables';
.modal--container {
  min-height: 600px;
}
.subtitle--text {
  margin-top: 20px;
  @media #{$phones} {
    margin-top: 60px;
    text-align: center;
  }
}
.add--button {
  margin-bottom: 36px;
}
</style>
